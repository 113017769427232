/*
// .address-card
*/
@import "../variables";
@import "../functions";
@import "../mixins/direction";

.address-card {
	position: relative;
}
.address-card__badge {
	position: absolute;
	background-color: var(--primary-bg);
	color: #fff;
	font-size: 11px;
	font-weight: $font-weight-medium;
	text-transform: uppercase;
	padding: 4px 8px 2px;
	top: -$card-border-width;

	@include direction {
		#{$inset-inline-end}: 0.75rem;
		#{$border-end-start-radius}: 2px;
		#{$border-end-end-radius}: 2px;
	}
}
.address-card__badge--muted {
	background-color: #f0f0f0;
	color: $light-opposite-color;
}
.address-card__body {
	padding: 1.5rem;
	font-size: 15px;
	line-height: 18px;
}
.address-card__name {
	font-size: 16px;
	font-weight: $font-weight-bold;
	margin-bottom: 1.125rem;
	background-color: transparent;
	border: none;
}
.address-card__row + .address-card__row {
	margin-top: 0.75rem;
}
.address-card__row-title {
	font-size: 12px;
	text-align: start;
	color: $card-font-muted-color;
}
.address-card__footer {
	margin-top: 1.625rem;
}

.address-card--featured {
	.address-card__body {
		padding: 2rem;
	}
	.address-card__name {
		font-weight: $font-weight-bold;
	}

	@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
		.address-card__body {
			padding: 1.5rem;
		}
	}
	@media (max-width: breakpoint(sm-end)) {
		.address-card__body {
			padding: 1.375rem;
		}
	}
}
