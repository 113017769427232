/*
// .totop
*/
@use "sass:math";
@import "../variables";
@import "../mixins/direction";

$local-size: 40px;
$local-bottom: 36px;
$local-margin-start: 80px;
$local-margin-end: 24px;

.whatsapp {
    position: relative;
    z-index: 9;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0s 0.3s;
}
.whatsapp__body {
    position: fixed;
    bottom: $local-bottom;
    right: 0;
    top: auto;
    width: 100%;
    display: flex;
    margin-top: -(math.ceil($local-size * 0.5));
}
.whatsapp__container {
    margin: 0 -15px;
}
.whatsapp__start,
.whatsapp__end {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
}
.whatsapp__start {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;

    &:before {
        content: "";
        flex-grow: 1;
    }
}
.whatsapp__button {
    pointer-events: auto;
    border-radius: $local-size * 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
    cursor: pointer;

    fill: currentColor;
    flex-shrink: 0;
    transition: background-color 0.15s, color 0.15s;

    @include direction {
        #{$margin-inline-start}: $local-margin-start;
        #{$margin-inline-end}: $local-margin-end;
    }

    &:focus {
        outline: none;
    }

    @media (hover: hover) {
        &:hover {
            color: $btn-primary-hover-font-color;
            background: #01a352;
        }
    }

    &:active {
        color: $btn-primary-active-font-color;
        background: #01a352;
    }

    svg {
        margin-top: -2px;
        margin-bottom: 1px;
    }
}

.whatsapp--show {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s, 0s;
}
.floating-whatsapp-button {
    right: 5rem;
}
.avatar_padding.chatBody .styles-module_messageBody__9-hwo{
    display: flex;
}
.chatBody {
    direction: ltr;
}
.chatBody input {
    text-align: right;
}

@media (max-width: breakpoint(sm-end)) {
    .floating-whatsapp-button {
        right: 3rem;
    }
}
