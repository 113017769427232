// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------
@import "./variables";

.deactive-btn {
    width: 200px;
    background-color: #d51010;
    color: #ffffff;
    font-size: 1rem;
    padding: 8px 15px;
    border-radius: 8px;
    border: none;
    outline: none;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: #9b0707;
    }
}

.active-btn {
    width: 200px;
    background-color: $btn-primary-bg-color;
    color: #ffffff;
    font-size: 1rem;
    padding: 8px 15px;
    border-radius: 8px;
    border: none;
    outline: none;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: $btn-primary-hover-bg-color;
    }
}

.site-preloader {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 99999;
    opacity: 1;

    &::before {
        box-sizing: border-box;
        content: "";
        display: block;
        position: absolute;
        left: calc(50% - 50px);
        top: calc(50% - 50px);
        width: 100px;
        height: 100px;
        border-radius: 50px;
        border: 3px solid rgba(0, 0, 0, 0.2);
        border-top-color: rgba(0, 0, 0, 0.6);

        animation-name: site-preloader-animation;
        animation-duration: 0.5s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    .site-preloader__fade {
        transition: opacity 0.3s;
        opacity: 0;
    }

    @keyframes site-preloader-animation {
        from {
            transform: rotateZ(0deg);
        }

        to {
            transform: rotateZ(360deg);
        }
    }
}
.site-preloader.style-2 {
    position: absolute;
}

.apply-coupon {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    .coupon {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 0.2rem;
        cursor: pointer;
        margin-bottom: 0.6rem;

        h6 {
            font-size: 1rem;
            font-weight: 600;
            color: #db190b;
            margin: 0;
        }

        svg {
            width: 18px;
            height: 18px;
        }
    }

    .coupon-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;

        .error {
            font-size: 0.8rem;
            font-weight: 600;
            color: #d51010;
        }

        .coupon-form {
            height: 42px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 5px;
            border: 1px solid var(--primary-bg);
            border-radius: 5px;

            input {
                height: 100%;
                font-weight: 600;
                padding: 0 5px;
                border: none;
                outline: none;
                box-shadow: none;

                &::placeholder {
                    font-weight: 500;
                }
            }

            button {
                height: 100%;
                font-size: 0.9rem;
                padding: 2px 10px;
                border-radius: 4px;
            }
        }
    }
}

ul.MuiPagination-ul {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    direction: ltr;
}
