/*
// .profile-card
*/
@import "../variables";

.profile-card__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.profile-card__avatar {
    width: 90px;
    height: 90px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 16px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        max-width: 100%;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        -ms-border-radius: 100%;
        -o-border-radius: 100%;
    }
}
.profile-card__name {
    font-weight: $font-weight-medium;
    line-height: 20px;
}
.profile-card__email {
    font-size: 15px;
    margin-bottom: 24px;
}
