/*
// .widget
*/
@import "../variables";

.widget__title {
	margin-bottom: 20px;
	font-size: 20px;
	line-height: inherit;
}
