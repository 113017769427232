iframe {
    pointer-events: unset !important;
}
.mada-payment-box {
    max-width: 450px;
    margin: 100px auto;
    padding: 0 15px;
}
.card-element {
    height: 225px !important;
}
.mada-payment-box .logo-box {
    text-align: center;
    margin-bottom: 50px;
}

.mada-payment-box .logo-box img {
    margin-bottom: 8px;
    border: 1px solid #ddd;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.submit-checkout-btn {
    height: 45px !important;
    border-radius: 6px !important;
    -webkit-border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    -ms-border-radius: 6px !important;
    -o-border-radius: 6px !important;
}
